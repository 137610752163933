import {
  fileToDataUrl,
  getImagePathObj,
  getOssKey,
  isOssKey,
  uploadOssFile
} from '@/utils/kerquUtil'

export const interceptApiUrl = (route: string) => {
  if (route.includes('/view?')) {
    const searchParams = new URLSearchParams(route.substring(6))
    const params = new URLSearchParams()
    const subfolder = searchParams.get('subfolder')
    let url = searchParams.get('filename')?.split(' ')[0]
    if (!url) return '/view'
    if (/^https?:\/\//.test(url) && isOssKey(url)) {
      url = getOssKey(url)
    }
    if (subfolder?.startsWith('temp/') && !url.startsWith('temp/')) {
      url = subfolder + '/' + url
    }
    params.set('filename', url)
    params.set('originalImage', 'true')
    return '/api/comfyui/view?' + params.toString()
  }
}

const wrapResponse = (data: any) => {
  return {
    status: 200,
    json: async () => data
  } as Response
}

export const interceptFetchApi = async (
  route: string,
  options?: RequestInit
) => {
  if (route.includes('/upload/mask')) {
    const formData = options!.body as FormData
    const maskFile = formData.get('image') as File
    const maskDataUrl = await fileToDataUrl(maskFile)
    const maskImage = new Image()
    maskImage.crossOrigin = 'Anonymous'
    maskImage.src = maskDataUrl
    await new Promise((resolve) => {
      maskImage.onload = (img) => {
        resolve(img)
      }
    })
    const canvasMask = document.createElement('canvas')
    canvasMask.width = maskImage.width
    canvasMask.height = maskImage.height
    const ctxMask = canvasMask.getContext('2d') as CanvasRenderingContext2D
    ctxMask.drawImage(maskImage, 0, 0)
    const maskImageData = ctxMask.getImageData(
      0,
      0,
      maskImage.width,
      maskImage.height
    )

    console.log(JSON.stringify(options))
    const originalImageRef = JSON.parse(formData.get('original_ref') as string)
    const originImage = new Image()
    originImage.crossOrigin = 'Anonymous'
    originImage.src = interceptApiUrl(
      `/view?filename=${encodeURIComponent(originalImageRef.filename as string)}`
    ) as string
    await new Promise((resolve) => {
      originImage.onload = (img) => {
        resolve(img)
      }
    })
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
    canvas.width = originImage.width
    canvas.height = originImage.height
    ctx.drawImage(originImage, 0, 0)
    const imageData = ctx.getImageData(
      0,
      0,
      originImage.width,
      originImage.height
    )
    for (let i = 3; i < imageData.data.length; i += 4) {
      imageData.data[i] = maskImageData.data[i]
    }
    ctx.putImageData(imageData, 0, 0)

    const dataUrl = canvas.toDataURL()
    const { ossKey } = await uploadOssFile({ dataUrl })

    return wrapResponse(getImagePathObj(ossKey as string))
  } else if (route.includes('/upload/image')) {
    const formData = options!.body as FormData
    const imageFile = formData.get('image') as File
    const { ossKey } = await uploadOssFile({ file: imageFile })
    return wrapResponse(getImagePathObj(ossKey as string))
  } else if (route === '/pysssss/workflows') {
    return wrapResponse([])
  } else if (route == '/impact/wildcards/list') {
    return wrapResponse({
      data: ['__samples/jewel__', '__samples/flower__']
    })
  } else if (route.includes('/settings/Comfy.CustomColorPalettes')) {
    return wrapResponse({})
  } else if (route === '/ttN/loras') {
    return wrapResponse([])
  } else if (route === '/embeddings') {
    return wrapResponse('')
  }
}
